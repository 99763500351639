<template xmlns="http://www.w3.org/1999/html">
   <div class="content-wrapper">
      <!-- Content Header (Page header) -->
      <div class="content-header">
         <div class="container-fluid">
            <div class="row mb-2">
               <div class="col-sm-6">
                  <h1 class="m-0">Artigos</h1>
               </div><!-- /.col -->
               <div class="col-sm-6">
                  <ol class="breadcrumb float-sm-right">
                     <li class="breadcrumb-item active">Dashboard</li>
                     <li class="breadcrumb-item active">Artigos</li>
                     <li v-if="isEditing" class="breadcrumb-item active">Editar Artigo</li>
                     <li v-else class="breadcrumb-item active">Criar Artigo</li>
                  </ol>
               </div><!-- /.col -->
            </div><!-- /.row -->
         </div><!-- /.container-fluid -->
      </div>
      <!-- /.content-header -->

      <!-- Main content -->
      <section class="content">
         <div class="container-fluid">
            <div class="row">

               <div class="col-md-12">
                  <div class="card card-outline card-info">

                     <div v-if="(isLoading || categories.length == 0)" class="d-flex justify-content-center">{{ $t('loading') }}</div>

                     <div v-else>
                        <div class="card-header">
                           <h3 class="card-title">
                              {{ isEditing ? $t('edit_article') : $t('create_article') }}
                           </h3>
                        </div>
                        <!-- /.card-header -->

                        <form @submit.prevent="save">
                           <div class="card-body">

                              <div class="row">

                                 <div class="col-6">
                                    <div class="form-group mb-4">
                                       <label for="exampleInputEmail1">{{ $t('title') }}*</label>
                                       <input v-model="article.title" type="text" class="form-control" placeholder="Título">
                                    </div>
                                 </div>

                                 <div class="col-6">
                                    <div class="form-group">
                                       <label>{{ $t('author') }}*</label>
                                       <select v-model="article.author" class="form-control">
                                          <option disabled selected>{{ $t('select_one_option') }}</option>
                                          <option
                                             :class="[{'selected': 'Antônio Cláudio Santos das Neves' === article.author }]"
                                             value="Antônio Cláudio Santos das Neves"
                                          >
                                             Antônio Cláudio Santos das Neves
                                          </option>
                                       </select>
                                    </div>
                                 </div>

                                 <div class="col-6">
                                    <div class="form-group">
                                       <label>{{ $t('categories') }}*</label>
                                       <VueMultiselect
                                          v-model="categoriesSelected"
                                          placeholder="Buscar uma categoria"
                                          select-label="Pressione o enter ou clique para selecionar"
                                          deselect-label="Pressione o enter ou clique para remover"
                                          label="name"
                                          track-by="uuid"
                                          :options="categoryOptions"
                                          :multiple="true"
                                          :taggable="true"
                                          @select="onSubcategoryInclude"
                                          @Remove="onSubcategoryRemove"
                                       ></VueMultiselect>
                                    </div>
                                 </div>

                                 <div class="col-2">
                                    <div class="form-group">
                                       <label>{{ $t('status') }}*</label>
                                       <select v-model="article.status" class="form-control">
                                          <option disabled selected>{{ $t('select_one_option') }}</option>
                                          <option value="draft" >{{ $t('draft') }}</option>
                                          <option value="published" >{{ $t('published') }}</option>
                                       </select>
                                    </div>
                                 </div>

                                 <div class="col-2 mt-3">
                                    <div class="form-group">
                                       <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                          <input type="checkbox" v-model="article.haveComments" class="custom-control-input" id="comentSwitch">
                                          <label :class="[article.haveComments ? 'text-green' : 'text-red', 'custom-control-label']" for="comentSwitch">
                                             {{ $t('comments') }}*
                                          </label>
                                       </div>
                                    </div>
                                 </div>

                                 <div class="col-2 mt-3">
                                    <div class="form-group">
                                       <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                          <input type="checkbox" v-model="article.isFree" class="custom-control-input" id="statusSwitch">
                                          <label :class="[article.isFree ? 'text-green' : 'text-red', 'custom-control-label']" for="statusSwitch">
                                             <i :class="[article.isFree ? 'fas fa-unlock' : 'fas fa-lock']"></i>
                                             {{ article.isFree === true  ? $t('free') : $t('paid') }}*
                                          </label>
                                       </div>
                                    </div>
                                 </div>

                                 <div class="col-12 mb-3">
                                    <label>{{ $t('subcategories') }}*</label>
                                    <VueMultiselect
                                       v-model="subcategoriesSelected"
                                       placeholder="Buscar uma subcategoria"
                                       select-label="Pressione o enter ou clique para selecionar"
                                       deselect-label="Pressione o enter ou clique para remover"
                                       label="name"
                                       track-by="uuid"
                                       :options="subcategoryOptions"
                                       :multiple="true"
                                       :taggable="true"
                                    ></VueMultiselect>
                                 </div>

                                 <div class="col-12 my-2">
                                    <label>{{ $t('article_cover') }}*</label>
                                    <div>
                                       <img
                                          v-if="article.imageUrl"
                                          :src="article.imageUrl"
                                          :alt="article.title"
                                          height="150"
                                          width="300"
                                       />
                                       <a v-else style="border-radius: 50%" class="btn btn-app">
                                          <i class="fas fa-image"></i> {{ $t('image') }}
                                       </a>
                                       <div class="row input-file-container">
                                          <input type="file" id="meuInputFile" class="input-file" @change="onImageChoose" style="display: none;" />
                                          <label for="meuInputFile" class="input-file-trigger btn btn-outline-secondary btn-sm mx-4 mt-2 mb-3">
                                             {{ alreadyIncludeImageCover ? $t('change') : $t('insert') }}
                                          </label>
                                       </div>
                                    </div>
                                 </div>

                                 <div class="col-12">
                                    <div class="form-group mb-4">
                                       <label>{{ $t('content') }}</label>

                                       <div v-for="(contentEditor, idx) in contentEditors" :key="idx" :class="idx === 0 ? 'mb-5' : ''">
                                          <hr v-if="idx > 0" >

                                          <div v-if="contentEditor.type === 'video' || contentEditor.type === 'image'">
                                             <span class="ml-1" style="font-size: 11.5px; font-style: italic">
                                                {{ $t('click_on_the_little_button_in_the_header_below_to_insert_the_image_or_video') }}.
                                             </span>
                                          </div>
                                          <QuillEditor
                                             theme="snow"
                                             :ref="`refContentInstance-${contentEditor.uuid}`"
                                             :toolbar="toolbar(contentEditor.type)"
                                             v-model:content="contentEditor.ArrayContent"
                                             :key="componentKey"
                                          />

                                          <div v-if="idx > 0" class="row justify-content-end">
                                             <button
                                                type="button"
                                                @click="deleteContentEditor(contentEditor.uuid)"
                                                class="btn btn-xs btn-danger m-2">
                                                <i class="fas fa-trash-alt"></i>
                                                {{ $t('exclude') }}
                                             </button>
                                          </div>
                                       </div>

                                       <div class="row justify-content-start mx-1 my-2">
                                          <select v-model="selectedTypeContent" class="form-control-sm">
                                             <option disabled selected>{{ $t('choose_the_type_of_content') }}</option>
                                             <option value="image">{{ $t('image') }}</option>
                                             <option value="text">{{ $t('text') }}</option>
                                             <option value="video">{{ $t('video') }}</option>
                                          </select>
                                          <button
                                             @click="addContentEditor(selectedTypeContent)"
                                             type="button"
                                             class="btn btn-default mx-2 btn-sm text-bold">
                                             <i class="fas fa-plus-circle"></i> {{ $t('add') }}
                                          </button>
                                       </div>
                                    </div>
                                 </div>
                              </div>

                           </div>
                           <div class="card-footer">
                              <div class="row justify-content-end">
                                 <SaveButton
                                    :buttonName="saveButtonName"
                                    :isSaving="isSaving"
                                 >
                                 </SaveButton>
                              </div>
                           </div>
                        </form>
                     </div>
                  </div>
               </div>
               <!-- /.col-->
            </div>
            <!-- ./row -->
         </div>
      </section>
   </div>
</template>

<script>
import { QuillEditor } from "@vueup/vue-quill"
import '@vueup/vue-quill/dist/vue-quill.snow.css'
import { v4 as uuidV4 } from "uuid"
import articleService from "../services/article-service"
import { useToast } from 'vue-toastification'
import { storageRef } from "../../../../../plugins/firebase"
import VueMultiselect from 'vue-multiselect'
import { mapState, mapMutations, mapActions } from "vuex"
import SaveButton from "../../../components/SaveButton.vue";

export default {
   name: "ArticlesForm",
   components: { QuillEditor, VueMultiselect, SaveButton },
   setup() {
      const toast = useToast()

      return { toast }
   },
   data () {
      return {
         article: {
            uuid: null,
            author: '',
            categories: '',
            isFree: true,
            image: null,
            imageUrl: null,
            haveComments: true,
            status: null,
            title: null,
            imageUrlToDelete: null
         },
         alreadyIncludeImageCover: false,
         categoriesSelected: [],
         categoryOptions: [],
         subcategoriesSelected: [],
         subcategoryOptions: [],
         componentKey: 0,
         contentEditors: [],
         errors: [],
         isSaving: false,
         isEditing: false,
         isLoading: false,
         selectedTypeContent: '',
         toolbarText: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{ 'header': 1 }, { 'header': 2 }],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'script': 'sub'}, { 'script': 'super' }],
            [{ 'indent': '-1'}, { 'indent': '+1' }],
            [{ 'direction': 'rtl' }],
            [{ 'size': ['small', false, 'large', 'huge'] }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'font': [] }],
            [{ 'align': [] }],
            ['clean']
         ],
         toolbarWithImage: ['image'],
         toolbarWithVideo: ['video']
      }
   },
   computed: {
      ...mapState({
         categories: state => state.category.allCategories,
         allArticlesCategoriesRelation: state => state.article.articles.allArticlesCategoriesRelation
      }),
      saveButtonName () {
         if (this.isEditing)
            return 'edit';
         else
            return 'save';
      }
   },
   async created() {
      this.isLoading = true;

      if (this.categories.length === 0)
         await this.getAllCategories();

      this.categories.forEach((cat) => {
         this.categoryOptions.push( { name: cat.name, uuid: cat.uuid });
      });

      const uuid = this.$route.params.uuid;
      if (uuid) {
         this.isEditing = true;
         this.alreadyIncludeImageCover = true;
         try {

            const { data: response } = await articleService.get(uuid);

            this.article.uuid = response.uuid;
            this.article.title = response.title;
            this.article.status = response.status;
            this.article.author = response.author;
            this.article.isFree = response.subscriptionType === 'free';
            this.article.haveComments = response.haveComments;
            this.article.imageUrl = response.image;
            this.article.createdAt = response.createdAt;
            this.article.imageUrlToDelete = response.image;

            //include categories
            response.categories.forEach((category) => {
               const categoryFound = this.categoryOptions.find((cat) => cat.uuid === category.uuid);
               this.categoriesSelected.push(categoryFound);
            });

            //include subcategories
            this.categoriesSelected.forEach((category) => {
               this.onSubcategoryInclude(category);
            });
            //selecting subcategories
            response.subcategories.forEach((subcategoryUuid) => {
               this.subcategoryOptions.forEach((subcat) => {
                  if (subcat.uuid === subcategoryUuid)
                     this.subcategoriesSelected.push(subcat);
               });
            });

            //include content(s)
            response.contents.forEach((content) => {

               const contentEdt = {
                  uuid: uuidV4(),
                  type: content.type,
                  content: content.rawContent,
                  ArrayContent: null,
                  rawContent: null
               }

               this.contentEditors.push(contentEdt);
            });

            this.contentEditors.forEach((content) => {
               const refName = `refContentInstance-${content.uuid}`;

               setTimeout(() => {
                  this.$refs[refName][0].setHTML(content.content);
               }, 1000);
            });
         } catch {}
      }
      else {
         this.contentEditors.push( {uuid: uuidV4(), type: 'text', content: null, ArrayContent: null, rawContent: null } );
      }

      this.isLoading = false;
   },
   methods: {
      ...mapActions(['getAllArticlesCategoriesRelation', 'getAllCategories']),
      ...mapMutations({
         setPaginationInfo: 'SET_ARTICLE_PAGINATION_INFO'
      }),
      addContentEditor (type) {
         if (type !== ''){

            const uuid = uuidV4();
            const contentEdt = {
               uuid: uuid,
               type,
               content: null,
               rawContent: null,
               ArrayContent: null
            }
            this.contentEditors.push(contentEdt)

            if (type === 'video'){
               const refName = `refContentInstance-${uuid}`;

               //centralizing the cursor
               setTimeout(() => {
                  this.$refs[refName].setHTML("<p class=\"ql-align-center\"><br></p>");
               }, 1000);
            }
         }
      },
      deleteContentEditor (uuid) {
         this.contentEditors = this.contentEditors.filter(
            (contEdt) => contEdt.uuid !== uuid
         );
         this.forceRenderer();
      },
      forceRenderer () {
         this.componentKey = +Date.now();
      },
      mapClassToStyle(className) {

         const classToStyleMapping = {
            "ql-align-center": "text-align: center;",
            "ql-align-left": "text-align: left;",
            "ql-align-right": "text-align: right;",
            "ql-align-justify": "text-align: justify;"
         };

         return classToStyleMapping[className] || '';
      },
      replaceClassWithStyle (htmlString) {

         return htmlString.replace(/class=\\?"(.*?)\\?"/g, (match, className) => {
            const style = this.mapClassToStyle(className);

            return style ? `style="${style}"` : match;
         });
      },
      onImageChoose (event) {
         const file = event.target.files[0]

         const reader = new FileReader()
         reader.onload = () => {
            // the field will be sent on backend as base64 string and apply validations
            this.article.image = reader.result;
            this.article.imageUrl = reader.result;
         }
         reader.readAsDataURL(file);
         this.alreadyIncludeImageCover = true;
      },
      onSubcategoryInclude (category) {

         this.categories.forEach((cat) => {
            if (cat.uuid == category.uuid){

               cat.subcategories.forEach((subCat) => {
                  this.subcategoryOptions.push({
                     name: subCat.name,
                     uuid: subCat.uuid,
                     categoryUuid: cat.uuid
                  });
               });
            }
         });
      },
      onSubcategoryRemove (category) {
         this.subcategoryOptions = this.subcategoryOptions.filter(
            (subCat) => subCat.categoryUuid !== category.uuid
         );
         this.subcategoriesSelected = this.subcategoriesSelected.filter(
            (subCat) => subCat.categoryUuid !== category.uuid
         );
      },
      toolbar (type) {
         switch (type) {
            case 'image':
               return this.toolbarWithImage;
               break;
            case 'text':
               return this.toolbarText;
               break;
            default:
               return this.toolbarWithVideo;
               break;
         }
      },
      async save () {
         this.resetErrors();
         this.isSaving = true;

         this.contentEditors.forEach((edt) => {

            const refName = `refContentInstance-${edt.uuid}`;
            const finalHtmlStr = this.replaceClassWithStyle(this.$refs[refName][0].getHTML());

            edt.content = finalHtmlStr;
            edt.rawContent = this.$refs[refName][0].getHTML();
         });

         const article = {
            uuid: this.isEditing ? this.article.uuid : uuidV4(),
            title: this.article.title,
            image:  this.article.imageUrl ?? '',
            categories: [],
            subcategories: [],
            author: this.article.author,
            contents: this.contentEditors,
            status: this.article.status,
            isFree: this.article.isFree,
            haveComments: this.article.haveComments,
            imageUrlToDelete: this.article.imageUrlToDelete ?? null
         }

         try {
            this.categoriesSelected.forEach((item) => {
               article.categories.push(item.uuid)
            });
            this.subcategoriesSelected.forEach((item) => {
               article.subcategories.push(item.uuid)
            });

            if (this.article.image !== null){
               const imgExtension = this.article.image.substring(this.article.image.indexOf("/") + 1, this.article.image.indexOf(";base64"));
               const imgName = `images/${uuidV4()}.${imgExtension}`;

               await storageRef.child(imgName).putString(this.article.image, 'data_url');
               article.image = await storageRef.child(imgName).getDownloadURL();
            }
            else {
               if (this.isEditing)
                  article.imageUrlToDelete = null;
            }

            if (this.isEditing){
               await this.getAllArticlesCategoriesRelation();
               article.allArticlesCategoriesRelation = this.allArticlesCategoriesRelation;
               article.createdAt = this.article.createdAt;
               await articleService.edit(article);
               this.toast.success(this.$t("updated"));
            }
            else{
               article.imageUrlToDelete = null;
               await articleService.create(article);
               this.toast.success(this.$t("saved"));
            }

            this.$router.push({ name: 'articles' });

            if (!this.isEditing){
               const { data: paginationInfo } = await articleService.getPaginationInfo();
               this.setPaginationInfo({ totalPages: paginationInfo.totalPages, data: paginationInfo.data, tokenPages: paginationInfo.tokens });
            }

         } catch (error) {
            const { status } = error.response;
            const errorResponse = error.response.data.errors;

            if (this.isEditing)
               this.toast.error(this.$t("update_failure"));
            else
               this.toast.error(this.$t("save_failure"));

            if (status === 422){
               this.errors = Object.assign(this.errors, errorResponse)
               Object.keys(this.errors).forEach((field) => {
                  this.errors[field].forEach((error) => {
                     this.toast.error(error);
                  });
               });
            }

         } finally {
            this.isSaving = false;
         }
      },
      resetErrors () {
         this.errors = []
      }
   }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.css">

.input-file-container {
   position: relative;
   display: flex;
   flex-direction: row;
   width: 200px; /* Largura do botão */
}

.input-file {
   width: 200px;
   height: 60px;
   opacity: 0; /* O input file fica invisível */
   position: absolute;
   top: 0;
   left: 0;
   cursor: pointer; /* Cursor do tipo ponteiro */
}

.input-file-trigger {
   display: inline-block;
   padding: 15px 32px;
   background-color: #4CAF50;
   color: white;
   border-radius: 4px;
   font-size: 16px;
   text-align: center;
   cursor: pointer;
   position: absolute;
   top: 0;
   left: 0;
   width: 200px;
   height: 60px;
   line-height: 30px; /* Centraliza o texto verticalmente */
}

.input-file-trigger:hover {
   background-color: #45a049;
}

</style>

